body {
  margin: 0;
  font-family: "Genos", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000 !important;
  color: #fff !important;
}

#logotext {
  font-weight: 800;
  font-size: 34px;
  color: rgb(255 47 191);
  margin-left: 30px;
}

.navbar-nav {
  --bs-nav-link-padding-y: 0px !important;
}

.nav-link {
  font-weight: 800 !important;
  font-size: 1.2em !important;
  color: rgb(89 190 255) !important;
  text-transform: uppercase !important;
  margin: 0px 10px;
}

.connectbutton {
  background-color: #000 !important;
  color: rgb(255 47 191) !important;
  border-color: rgb(255 47 191) !important;
  border-radius: 45px !important;
  font-weight: 800 !important;
  font-size: 1.2em !important;
  padding: 10px 24px 14px 24px;
  line-height: 30%;
}

.buybutton {
  border: solid 1px;
  border-color: rgb(89 190 255) !important;
  border-radius: 45px !important;
  padding: 2px 10px 0px 10px !important;
  cursor: pointer;
}

.redeembutton {
  border-radius: 15px;
  font-size: 3.5em !important;
  font-weight: 900 !important;
  display: block;
  width: 100% !important;
  color: rgb(255, 47, 191) !important;
  border: solid 1px;
  border-color: rgb(89, 190, 255) !important;
  border-radius: 40px;
  background-color: #000;
  background-image: linear-gradient(
    to top,
    rgb(255, 47, 191, 0.8) 10%,
    rgba(0, 0, 0) 60%
  );
  cursor: pointer;
}

.bannertext {
  font-weight: 900 !important;
  color: rgb(89 190 255) !important;
  line-height: 70%;
}

.bannersubtext {
  font-weight: 600 !important;
  color: white !important;
}

.bannerpink {
  font-weight: 900 !important;
  color: rgb(255 47 191) !important;
  line-height: 70%;
}

.bannerpink2 {
  font-weight: 900 !important;
  color: rgb(255 47 191) !important;
  font-size: 5em !important;
  line-height: 70%;
}

.bannerbluebigger {
  font-weight: 900 !important;
  color: rgb(89 190 255) !important;
  font-size: 5em !important;
  line-height: 120%;
  margin-top: 100px !important;
}

#bannercontainer {
  padding: 100px 0px 20px 0px !important;
}

#socialcontainer {
  padding: 60px 0px 30px 0px !important;
}

.divider-gradient {
  height: 3px; /* or whatever thickness you want for your divider */
  width: 100%; /* full width */
  background: linear-gradient(
    90deg,
    rgba(255, 47, 190, 0) 0%,
    rgba(255, 47, 190, 1) 50%,
    rgba(255, 47, 190, 0) 100%
  );
  margin: 20px 0; /* optional, adds some spacing around the divider */
}

.divider-gradient-blue {
  height: 3px; /* or whatever thickness you want for your divider */
  width: 100%; /* full width */
  background: linear-gradient(
    90deg,
    rgba(255, 47, 190, 0) 0%,
    rgb(89 190 255) 50%,
    rgba(255, 47, 190, 0) 100%
  );
  margin: 20px 0; /* optional, adds some spacing around the divider */
}

.bulletpoint {
  font-weight: 900 !important;
  color: rgb(255 47 191) !important;
  font-size: 3em !important;
  line-height: 120%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 20px;
  border: solid 1px;
  border-color: rgb(89 190 255) !important;
  border-radius: 90px !important;
  padding-bottom: 8px;
}

.outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: "0px 100px";
}

.fadebackground {
  background-color: #fff;
  border-radius: 80px;
  background: linear-gradient(to bottom, rgb(28 27 28), black);
  z-index: -1;
}

.fadebackgroundpink {
  background-color: #fff;
  border-radius: 80px;
  background: linear-gradient(
    to bottom,
    rgb(235 45 176) 0%,
    rgb(123, 24, 92),
    black
  );
  padding: 100px 100px;
  z-index: -1;
}

.bulletpoint2 {
  font-weight: 900 !important;
  color: rgb(89 190 255) !important;
  font-size: 3.2em !important;
  line-height: 120%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  border: solid 1px;
  border-color: rgb(89 190 255) !important;
  border-bottom-color: transparent !important;

  border-radius: 15px 15px 15px 15px !important;
  background-color: rgb(17 16 17);

  margin: 50px 30px;
  padding: 20px 0px;
}

.bullettitle {
  font-weight: 800 !important;
  font-size: 0.65em;
}

.bulletdescription {
  color: white;
  font-size: 0.5em;
  font-weight: 600;
}

.bulletpoint2pink {
  font-weight: 900 !important;
  color: rgb(255 47 191) !important;
  font-size: 3.2em !important;
  line-height: 120%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  border: solid 1px;
  border-color: rgb(255 47 191) !important;
  border-bottom-color: transparent !important;

  border-radius: 15px 15px 15px 15px !important;
  background-color: rgb(17 16 17);

  margin: 50px 30px;
  padding: 20px 0px;
}

.howitworks {
  font-weight: 900 !important;
  color: rgb(255 47 191) !important;
  font-size: 6em !important;
  line-height: 120%;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  border: solid 1px;
  border-color: rgb(89 190 255) !important;
  border-radius: 60px !important;
}

.worktitle {
  font-weight: 900 !important;
  color: rgb(89 190 255) !important;
  font-size: 3.5em !important;
}

.workbullet {
  font-weight: 900 !important;
  color: rgb(255 47 191) !important;
  font-size: 10em !important;
  background-color: rgb(17 16 17);
  height: 100%;
  display: grid;
  place-items: center;
  border-radius: 15px !important;
  border: solid 1px;
  border-color: rgb(89 190 255) !important;
}

.workdescription {
  font-weight: 600 !important;
  font-size: 1.5em !important;
}

.introducing {
  font-weight: 900 !important;
  font-size: 7em !important;
  color: #000;
  text-shadow: 4px 4px 0px rgb(89, 190, 255);
}

.espr {
  font-weight: 900 !important;
  font-size: 15em !important;
  color: #000;
  text-shadow: 4px 4px 0px rgb(89, 190, 255);
  line-height: 25%;
}

.whitetext {
  font-weight: 800 !important;
  font-size: 1.8em !important;
  color: rgb(89 190 255) !important;
}

.bullet {
  background-color: #000;
  font-weight: 500 !important;
  font-size: 1.8em !important;
  padding: 20px 44px;
  border: solid 1px;
  border-color: rgb(89 190 255) !important;
  border-radius: 90px;
}

.bullet2 {
  background-color: #000;
  font-weight: 900 !important;
  font-size: 4.5em !important;
  color: rgb(89 190 255) !important;
  padding: 10px 44px 15px 44px;
  border: solid 1px;
  border-color: rgb(89 190 255) !important;
  border-radius: 90px;
  display: block;
  width: 100% !important;
  margin-bottom: 20px;
}

.buybutton2 {
  border-radius: 15px;
  font-size: 6em !important;
  font-weight: 900 !important;
  display: block;
  width: 100% !important;
  color: rgb(255, 47, 191) !important;
  border: solid 1px;
  border-color: rgb(89, 190, 255) !important;
  border-radius: 40px;
  padding: 10px 130px !important;
  background-color: #000;
  background-image: linear-gradient(
    to top,
    rgb(255, 47, 191, 0.8) 10%,
    rgba(0, 0, 0) 60%
  );
  cursor: pointer;
}

.buybutton2:hover {
  background-color: #000 !important;
  background-image: linear-gradient(
    to top,
    rgb(89 190 255) 10%,
    rgba(0, 0, 0) 60%
  );
}

.redeembutton:hover {
  background-color: #000 !important;
  background-image: linear-gradient(
    to top,
    rgb(89 190 255) 10%,
    rgba(0, 0, 0) 60%
  );
}

.footerelem {
  font-size: 1.5em !important;
}

.footertitle {
  font-size: 1.8em !important;
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 300% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@media (max-width: 768px) {
  .bannertext {
    font-size: 3.2em !important;
    line-height: 120%;
  }
  .bannersubtext {
    font-size: 1.5em !important;
  }
  #logotext {
    font-size: 30px;
  }
  .bannerpink {
    font-size: 2em !important;
    line-height: 40px;
  }
  .bulletpoint {
    font-size: 2em !important;
    margin-top: 20px !important;
  }
  .bulletpoint2 {
    font-size: 2.3em !important;
    margin: 10px 10px;
    padding: 10px 20px 20px 20px;
  }
  .bulletpoint2 > h1 {
    font-size: 0.8em !important;
    font-weight: 900;
    margin: 10px 10px;
  }
  .fadebackground {
    padding: 14px 0px;
    border-radius: 40px;
  }
  .bulletpoint2pink {
    font-size: 2.3em !important;
    margin: 10px 10px;
    padding: 0px 10px;
  }
  .bulletpoint2pink > h1 {
    font-weight: 900;
    margin-top: 20px;
  }
  .bulletdescription {
    font-size: 0.7em;
    font-weight: 600;
  }
  .bannerpink2 {
    font-size: 4em !important;
    line-height: 120%;
  }
  .bannerbluebigger {
    font-size: 3.6em !important;
    line-height: 120%;
  }
  .howitworks {
    font-size: 3.5em !important;
    padding: 20px !important;
  }
  .worktitle {
    font-size: 2.5em !important;
  }
  .outer-container {
    padding: 0px 0px !important;
  }
  .fadebackgroundpink {
    padding: 14px 0px;
    border-radius: 40px;
  }
  .introducing {
    font-size: 2.5em !important;
  }
  .espr {
    font-size: 5.5em !important;
  }
  .bullet {
    padding: 30px 20px;
    font-size: 1.5em !important;
    border-radius: 50px;
  }

  .bullet2 {
    padding: 30px 20px;
    font-size: 1.5em !important;
    border-radius: 30px;
  }

  .buybutton2 {
    font-size: 3.3em !important;
    border-radius: 30px;
    padding: 15px 0px 20px 0px !important;
    line-height: 90%;
  }

  .redeembutton {
    font-size: 2.8em !important;
    line-height: 90%;
  }

  .connectbutton {
    line-height: 120%;
  }

  #bannercontainer {
    padding: 50px 0px !important;
    min-height: 32vh;
  }
}

@media (min-width: 768px) {
  .bannertext {
    font-size: 5em !important;
    line-height: 110%;
  }
  .bannersubtext {
    font-size: 1.2em !important;
  }
  #logotext {
    font-size: 34px;
  }
  .bannerpink {
    font-size: 5em !important;
    line-height: 120%;
  }
  .bulletpoint {
    font-size: 3em !important;
  }
  .bulletpoint2 {
    font-size: 3.2em !important;
    margin: 10px 30px;
  }
  .fadebackground {
    padding: 100px 100px;
  }
}
